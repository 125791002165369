var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { infoSelect, parsedProduct, validate } from 'services/api/product-service';
import { REQUEST_TYPE_QUOTE, PRODUCTBUILD_TABS, REQUEST_TYPE_ORDER, PRODUCT_CLASS_ALLOWED, ROYALTIY_SCOPES, } from 'utils/constants';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { PubTabs } from 'components/Shared/PubTabs/PubTabs';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { createNavigationURL } from 'components/ParsedProduct/ParsedProductSearchForm/ParsedProductSearchForm';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { toUTCTimestamp } from 'services/helperFunctions/toUTCTimestamp';
import { BuildProductDetails } from './BuildBox/BuildProductDetailsV2/BuildProductDetails';
import { OptionsTab } from '../ProductBuildTabs/OptionsTab/OptionsTab';
import { RestrictionsTab } from '../ProductBuildTabs/RestrictionsTab/RestrictionsTab';
import { RoyaltiesTab } from '../ProductBuildTabs/RoyaltiesTab/RoyaltiesTab';
import styles from './ProductBuildPageV2.module.scss';
export function ProductBuildPageV2() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var loaderData = useLoaderData();
    var userHasRoyaltyPrivileges = useCheckAuthScope(ROYALTIY_SCOPES);
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var throwErrorAlert = useCallback(function () {
        setSnackbarAlert({
            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
            type: 'error',
        });
    }, [setSnackbarAlert]);
    useEffect(function () {
        if (loaderData.isError) {
            throwErrorAlert();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var location = useLocation();
    var navigate = useNavigate();
    var _o = useState(sessionStorage.getItem('latestValidate')
        ? JSON.parse(sessionStorage.getItem('latestValidate'))
        : (_a = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _a === void 0 ? void 0 : _a.body), latestValidateResponse = _o[0], setLatestValidateResponse = _o[1];
    var showCustom = useMemo(function () { var _a, _b, _c; return PRODUCT_CLASS_ALLOWED.includes((_c = (_b = (_a = loaderData.infoRsp.body.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.productClass) !== null && _c !== void 0 ? _c : ''); }, [(_c = (_b = loaderData.infoRsp.body.details) === null || _b === void 0 ? void 0 : _b.header) === null || _c === void 0 ? void 0 : _c.productClass]);
    var buildMode = useMemo(function () { return location.pathname.split('/')[2] === 'build'; }, [location]); // use this to determine if page is read only
    var validateMode = useMemo(function () { return location.pathname.split('/')[2] === 'validate'; }, [location]);
    var parsedProductMode = useMemo(function () { return location.pathname.split('/')[1] === 'parsed-product'; }, [location]);
    var searchParams = useSearchParams()[0];
    var _p = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        if (parsedProductMode) {
            if (location === null || location === void 0 ? void 0 : location.state) {
                sessionStorage.setItem('parsedProductPayload', JSON.stringify(__assign(__assign({}, location === null || location === void 0 ? void 0 : location.state), { fromParsedPage: true })));
            }
        }
        var isFromValidatePage = sessionStorage.getItem('navigatedFrom') === 'productValidate';
        var isFromParsedDetailsPage = sessionStorage.getItem('navigatedFrom') === 'parsedProduct';
        sessionStorage.removeItem('navigatedFrom');
        var todaysDate = new Date();
        var newParsedTimeStamp = toUTCTimestamp(todaysDate.toISOString());
        var urlPayload = parsedProductMode
            ? {
                text: (_c = (_b = (_a = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.productNbrOut) !== null && _c !== void 0 ? _c : '',
                timestamp: (_d = searchParams.get('parsedTimestamp')) !== null && _d !== void 0 ? _d : '',
                date: newParsedTimeStamp.parsedDateString,
                time: newParsedTimeStamp.parsedTimeString,
                productId: (_g = (_f = (_e = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _e === void 0 ? void 0 : _e.body) === null || _f === void 0 ? void 0 : _f.productId) !== null && _g !== void 0 ? _g : '',
                channelValue: 'Commercial',
                productVersion: (_q = (_k = (_j = (_h = loaderData.validateRsp) === null || _h === void 0 ? void 0 : _h.body) === null || _j === void 0 ? void 0 : _j.productVersion) !== null && _k !== void 0 ? _k : (_p = (_o = (_m = (_l = loaderData.infoRsp) === null || _l === void 0 ? void 0 : _l.body) === null || _m === void 0 ? void 0 : _m.details) === null || _o === void 0 ? void 0 : _o.header) === null || _p === void 0 ? void 0 : _p.productVersion) !== null && _q !== void 0 ? _q : '',
                fromValidatePage: isFromValidatePage,
                search: searchParams.get('search'),
                fromParsedProduct: isFromParsedDetailsPage,
            }
            : {
                text: (_r = searchParams.get('productNbr')) !== null && _r !== void 0 ? _r : '',
                timestamp: (_s = "".concat(searchParams.get('date'), "T").concat(searchParams.get('time'), ".000Z")) !== null && _s !== void 0 ? _s : '',
                date: (_t = searchParams.get('date')) !== null && _t !== void 0 ? _t : '',
                time: (_u = searchParams.get('time')) !== null && _u !== void 0 ? _u : '',
                productId: (_v = searchParams.get('pid')) !== null && _v !== void 0 ? _v : '',
                channelValue: (_w = searchParams.get('channel')) !== null && _w !== void 0 ? _w : '',
                productVersion: (_x = searchParams.get('productVersion')) !== null && _x !== void 0 ? _x : '',
                fromValidatePage: isFromValidatePage,
                search: searchParams.get('search'),
                fromParsedProduct: isFromParsedDetailsPage,
            };
        return urlPayload;
    }, [
        (_f = (_e = (_d = loaderData === null || loaderData === void 0 ? void 0 : loaderData.infoRsp) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.details) === null || _f === void 0 ? void 0 : _f.header,
        (_h = (_g = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _g === void 0 ? void 0 : _g.body) === null || _h === void 0 ? void 0 : _h.productId,
        (_k = (_j = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _j === void 0 ? void 0 : _j.body) === null || _k === void 0 ? void 0 : _k.productNbrOut,
        (_m = (_l = loaderData === null || loaderData === void 0 ? void 0 : loaderData.validateRsp) === null || _l === void 0 ? void 0 : _l.body) === null || _m === void 0 ? void 0 : _m.productVersion,
        location === null || location === void 0 ? void 0 : location.state,
        parsedProductMode,
        searchParams,
    ]), text = _p.text, timestamp = _p.timestamp, date = _p.date, time = _p.time, productId = _p.productId, channelValue = _p.channelValue, productVersion = _p.productVersion, fromValidatePage = _p.fromValidatePage, search = _p.search, fromParsedProduct = _p.fromParsedProduct;
    var _q = useState(loaderData.restrictionsPresent), isProductRestrictions = _q[0], setIsProductRestrictions = _q[1];
    var _r = useState(), isOptionRestrictions = _r[0], setIsOptionRestrictions = _r[1];
    var _s = useState(), listOfParents = _s[0], setListOfParents = _s[1];
    var handleBackwardsNavigate = function () {
        var _a, _b, _c, _d, _e, _f;
        if (fromParsedProduct) {
            setLoading(true);
            var parsedParamDetails = JSON.parse(sessionStorage.getItem('parsedProductPayload'));
            sessionStorage.removeItem('parsedProductPayload');
            navigate("/parsed-product/details?parsedProductId=".concat((_a = parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.item) === null || _a === void 0 ? void 0 : _a.parsedProductId, "&parsedTimestamp=").concat((_b = parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.item) === null || _b === void 0 ? void 0 : _b.parsedTimestamp), sessionStorage.getItem('buildGateway') === 'multi'
                ? {
                    state: {
                        item: parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.item,
                        params: parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.params,
                    },
                }
                : {});
            return;
        }
        if (parsedProductMode) {
            sessionStorage.removeItem('parsedProductPayload');
            if ((_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.params) {
                var _g = (_e = (_d = location === null || location === void 0 ? void 0 : location.state) === null || _d === void 0 ? void 0 : _d.params) !== null && _e !== void 0 ? _e : {}, textVal = _g.textVal, dateVal = _g.dateVal, timeVal = _g.timeVal, parsedProductId = _g.parsedProductId;
                navigate("/parsed-product/multi?".concat(createNavigationURL(textVal, dateVal, timeVal, parsedProductId !== null && parsedProductId !== void 0 ? parsedProductId : '')));
            }
            else {
                navigate("/parsed-product/search");
            }
            return;
        }
        if (validateMode) {
            navigate('/product-validate-v2/search');
            return;
        }
        if (fromValidatePage) {
            setLoading(true);
            navigate("/product-validate-v2/validate?productNbr=".concat(text, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(channelValue, "&pid=").concat(productId, "&productVersion=").concat(productVersion));
            return;
        }
        if (((_f = location === null || location === void 0 ? void 0 : location.state) === null || _f === void 0 ? void 0 : _f.navigatedFrom) === 'productInfo') {
            setLoading(true);
            navigate("/product-info-v2/info?prefix=".concat(text, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(channelValue, "&pid=").concat(productId, "&productVersion=").concat(productVersion), { state: { navigatedFrom: 'productBuild' } });
            return;
        }
        if (sessionStorage.getItem('buildGateway') !== 'multi') {
            navigate('/product-build-v2/search');
        }
        else {
            navigate("/product-build-v2/multi?productNbr=".concat(search, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(channelValue), {
                state: { data: JSON.parse(sessionStorage.getItem('rawMultiData')).data },
            });
        }
    };
    var _t = useState(0), activeIndex = _t[0], setActiveIndex = _t[1];
    var _u = useState(false), loading = _u[0], setLoading = _u[1];
    var updatedValidationResp = useCallback(function (validationResp, productRestrictions, optionRestrictions) {
        setLatestValidateResponse(sessionStorage.getItem('latestValidate')
            ? JSON.parse(sessionStorage.getItem('latestValidate'))
            : validationResp);
        setIsProductRestrictions(productRestrictions);
        setIsOptionRestrictions(optionRestrictions);
    }, []);
    var updateKitsValue = function (e) {
        setListOfParents(e);
    };
    var tabsDef = useMemo(function () {
        var tabsDefinition = {
            tabs: [
                {
                    tab: {
                        labelText: PRODUCTBUILD_TABS.options.display,
                        tabKey: PRODUCTBUILD_TABS.options.key,
                        href: "".concat(location.pathname, "?tab=").concat(PRODUCTBUILD_TABS.options.key),
                    },
                    tabPanel: {
                        component: (_jsx(OptionsTab, { changedValidationResp: updatedValidationResp, buildMode: buildMode, showCustom: showCustom, updateKitsValue: function (e) {
                                return updateKitsValue(e);
                            }, userHasRoyaltyPrivileges: userHasRoyaltyPrivileges })),
                    },
                },
            ],
        };
        if (loaderData === null || loaderData === void 0 ? void 0 : loaderData.restrictionsPresent) {
            tabsDefinition.tabs.push({
                tab: {
                    labelText: PRODUCTBUILD_TABS.productLevelRestrictions.display,
                    tabKey: PRODUCTBUILD_TABS.productLevelRestrictions.key,
                    href: "".concat(location.pathname, "?tab=").concat(PRODUCTBUILD_TABS.productLevelRestrictions.key),
                },
                tabPanel: { component: _jsx(RestrictionsTab, { latestValidateResponse: latestValidateResponse }) },
            });
        }
        if ((loaderData === null || loaderData === void 0 ? void 0 : loaderData.royaltiesPresent) && userHasRoyaltyPrivileges) {
            tabsDefinition.tabs.push({
                tab: {
                    labelText: PRODUCTBUILD_TABS.productLevelRoyalties.display,
                    tabKey: PRODUCTBUILD_TABS.productLevelRoyalties.key,
                    href: "".concat(location.pathname, "?tab=").concat(PRODUCTBUILD_TABS.productLevelRoyalties.key),
                },
                tabPanel: { component: _jsx(RoyaltiesTab, { latestValidateResponse: latestValidateResponse }) },
            });
        }
        return tabsDefinition;
    }, [
        location.pathname,
        updatedValidationResp,
        buildMode,
        showCustom,
        loaderData === null || loaderData === void 0 ? void 0 : loaderData.restrictionsPresent,
        loaderData === null || loaderData === void 0 ? void 0 : loaderData.royaltiesPresent,
        userHasRoyaltyPrivileges,
        latestValidateResponse,
    ]);
    var key = 'tab';
    var handleTabChange = useCallback(function (index) {
        setActiveIndex(index);
        searchParams.set(key, tabsDef.tabs[index].tab.tabKey);
    }, [searchParams, tabsDef.tabs]);
    useLayoutEffect(function () {
        var activeTab = searchParams.get(key);
        var index = tabsDef.tabs.findIndex(function (t) { var _a; return (_a = t.tab.href) === null || _a === void 0 ? void 0 : _a.includes(activeTab !== null && activeTab !== void 0 ? activeTab : '!@#'); });
        setActiveIndex(index === -1 ? 0 : index);
    }, [searchParams, tabsDef.tabs]);
    return (_jsx(Box, { children: !loading ? (_jsxs(Box, __assign({ sx: { margin: '0 40px' } }, { children: [_jsxs(Button, __assign({ onClick: function () { return handleBackwardsNavigate(); }, sx: { width: '100px' } }, { children: [_jsx(KeyboardBackspaceIcon, {}), _jsx("span", __assign({ style: {
                                marginLeft: '20px',
                                fontFamily: 'UntitledSansMedium, sans-serif, system-ui',
                                fontSize: '16px',
                            } }, { children: "Back" }))] })), _jsx("hr", { className: styles.hr }), (isProductRestrictions || isOptionRestrictions) && (_jsxs(Box, __assign({ className: styles.restrictionsBox }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: !isProductRestrictions ? 'PARTIALLY RESTRICTED PRODUCT' : 'RESTRICTED PRODUCT' })), _jsx(Typography, __assign({ className: styles.header }, { children: "(Some options available only for pre-release testing)".toUpperCase() }))] }))), _jsx(Box, __assign({ className: styles.container }, { children: _jsx(BuildProductDetails, { latestValidateResponse: latestValidateResponse, infoResponse: loaderData.infoRsp.body, routeItems: { text: text, date: date, time: time, channelValue: channelValue, productId: productId, productVersion: productVersion }, timestamp: timestamp, channelVal: channelValue, buildMode: buildMode, showCustom: showCustom, listOfKitParents: listOfParents }) })), _jsx(PubTabs, { tabsDefinition: tabsDef, activeIndex: activeIndex, setActiveIndex: handleTabChange })] }))) : (_jsx(LoadingSpinner, { indicatorSize: 10 })) }));
}
export function ProductBuildPageV2Loader(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var request = _a.request;
    return __awaiter(this, void 0, void 0, function () {
        var sParams, searchParams, isParsed, isError, validateRsp, parsedResult, productIdVal, infoRsp, currentDate_1, restrictions, validRestrictions, restrictionsPresent, royaltiesPresent, optionRestrictionsPresent_1;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    sParams = new URL(request.url).searchParams;
                    searchParams = {
                        productNbr: sParams.get('productNbr'),
                        date: sParams.get('date'),
                        time: sParams.get('time'),
                        channel: sParams.get('channel'),
                        id: sParams.get('pid'),
                        version: sParams.get('productVersion'),
                        parsedProductId: sParams.get('parsedProductId'),
                        parsedTimestamp: sParams.get('parsedTimestamp'),
                    };
                    sessionStorage.removeItem('latestValidate');
                    isParsed = false;
                    isError = false;
                    if (searchParams.parsedProductId) {
                        isParsed = true;
                    }
                    if (!(sessionStorage.getItem('baanDataTransferring') !== 'true')) return [3 /*break*/, 6];
                    if (!isParsed) return [3 /*break*/, 2];
                    if (!searchParams.time) {
                        searchParams.time = '00:00:00';
                    }
                    return [4 /*yield*/, parsedProduct({
                            parsedProductId: +searchParams.parsedProductId,
                            requestType: REQUEST_TYPE_ORDER,
                            processingDate: searchParams.date ? "".concat(searchParams.date, "T").concat(searchParams.time, ".000Z") : undefined,
                            parsedTimestamp: searchParams.parsedTimestamp ? searchParams.parsedTimestamp : undefined,
                        })];
                case 1:
                    parsedResult = _k.sent();
                    if (parsedResult.status >= 500) {
                        isError = true;
                    }
                    else {
                        validateRsp = mapParsedResultsToValidateResponse(parsedResult.body);
                        productIdVal = {
                            productVersion: validateRsp.body.productVersion,
                            productId: validateRsp.body.productId,
                        };
                    }
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, validate({
                        productNbr: (_b = searchParams.productNbr) !== null && _b !== void 0 ? _b : '',
                        requestType: REQUEST_TYPE_QUOTE,
                        processingDate: "".concat(searchParams.date, "T").concat(searchParams.time, ".000Z"),
                        productId: (_c = searchParams.id) !== null && _c !== void 0 ? _c : '',
                        returnNextFeature: true,
                    })];
                case 3:
                    validateRsp = _k.sent();
                    if (validateRsp.status >= 500) {
                        isError = true;
                    }
                    else {
                        sessionStorage.setItem('validateRsp', JSON.stringify(validateRsp));
                    }
                    _k.label = 4;
                case 4: return [4 /*yield*/, infoSelect(isParsed
                        ? __assign({}, productIdVal) : {
                        productVersion: (_d = searchParams.version) !== null && _d !== void 0 ? _d : '',
                    }, 'header,features,hierarchy')];
                case 5:
                    infoRsp = _k.sent();
                    if (infoRsp.status >= 500) {
                        isError = true;
                    }
                    else {
                        sessionStorage.setItem('infoRsp', JSON.stringify(infoRsp));
                    }
                    currentDate_1 = DateTime.now().toFormat('yyyy-MM-dd');
                    restrictions = (_e = validateRsp === null || validateRsp === void 0 ? void 0 : validateRsp.body) === null || _e === void 0 ? void 0 : _e.restrictions;
                    validRestrictions = restrictions === null || restrictions === void 0 ? void 0 : restrictions.filter(function (r) { return !r.endDate || (r.endDate && r.endDate > currentDate_1); });
                    restrictionsPresent = (validRestrictions === null || validRestrictions === void 0 ? void 0 : validRestrictions.length) > 0;
                    royaltiesPresent = ((_g = (_f = validateRsp === null || validateRsp === void 0 ? void 0 : validateRsp.body) === null || _f === void 0 ? void 0 : _f.royalties) === null || _g === void 0 ? void 0 : _g.length) > 0;
                    (_j = (_h = validateRsp === null || validateRsp === void 0 ? void 0 : validateRsp.body) === null || _h === void 0 ? void 0 : _h.features) === null || _j === void 0 ? void 0 : _j.every(function (f) {
                        var _a;
                        var validOptionRestrictions = (_a = f.optionRestrictions) === null || _a === void 0 ? void 0 : _a.filter(function (r) { return !r.endDate || (r.endDate && r.endDate > currentDate_1); });
                        if ((validOptionRestrictions === null || validOptionRestrictions === void 0 ? void 0 : validOptionRestrictions.length) > 0) {
                            optionRestrictionsPresent_1 = true;
                            return false;
                        }
                        optionRestrictionsPresent_1 = false;
                        return true;
                    });
                    sessionStorage.setItem('productRestrictionsPresent', JSON.stringify(restrictionsPresent));
                    sessionStorage.setItem('productRoyaltiesPresent', JSON.stringify(royaltiesPresent));
                    sessionStorage.setItem('optionRestrictionsPresent', JSON.stringify(optionRestrictionsPresent_1));
                    return [2 /*return*/, { validateRsp: validateRsp, restrictionsPresent: restrictionsPresent, royaltiesPresent: royaltiesPresent, optionRestrictionsPresent: optionRestrictionsPresent_1, infoRsp: infoRsp, isError: isError }];
                case 6: return [2 /*return*/, {
                        validateRsp: JSON.parse(sessionStorage.getItem('validateRsp')),
                        restrictionsPresent: JSON.parse(sessionStorage.getItem('productRestrictionsPresent')),
                        royaltiesPresent: JSON.parse(sessionStorage.getItem('productRoyaltiesPresent')),
                        optionRestrictionsPresent: JSON.parse(sessionStorage.getItem('optionRestrictionsPresent')),
                        infoRsp: JSON.parse(sessionStorage.getItem('infoRsp')),
                        isError: isError,
                    }];
            }
        });
    });
}
function mapParsedResultsToValidateResponse(parsedRes) {
    var _a;
    return {
        status: 200,
        body: {
            returnCode: (_a = parsedRes.returnCode) === null || _a === void 0 ? void 0 : _a.toString(),
            returnMessage: parsedRes.returnMessage,
            completeProductInd: parsedRes.returnCode === 0,
            inputProductNbr: parsedRes.productNbrOut,
            inputProductId: parsedRes.productId,
            productNbrOut: parsedRes.productNbrOut,
            productVersion: parsedRes.productVersion,
            productPrefix: parsedRes.productPrefix,
            productName: parsedRes.productName,
            productId: parsedRes.productId,
            legacyProductId: parsedRes.legacyProductId,
            baseProductNbr: parsedRes.baseProductNbr,
            salesCategory: parsedRes.salesCategory,
            businessUnitCode: parsedRes.businessUnitCode,
            productLineCode: parsedRes.productLineCode,
            productLinemodelCode: parsedRes.productLinemodelCode,
            productLineDesc: parsedRes.productLineDesc,
            productFunctionCode: parsedRes.productFunctionCode,
            productFunctionmodelCode: parsedRes.productFunctionmodelCode,
            productFunctionDesc: parsedRes.productFunctionDesc,
            productClass: parsedRes.productClass,
            brandCode: parsedRes.brandCode,
            productType: parsedRes.productType,
            priceMatrixId: parsedRes.priceMatrixId,
            weightClassCode: parsedRes.weightClassCode,
            minLeadtime: parsedRes.minLeadtime,
            cetConnectorCode: parsedRes.cetConnectorCode,
            cetVisibleIndicator: parsedRes.cetVisibleIndicator,
            gsaInd: parsedRes.gsaInd,
            b2bInd: parsedRes.b2bInd,
            discountCode: parsedRes.discountCode,
            discountDesc: parsedRes.discountDesc,
            genericCode: parsedRes.genericCode,
            standardProductReference: parsedRes.standardProductReference,
            deviationDesc: parsedRes.deviationDesc,
            fscType: parsedRes.fscType,
            fscChainOfCustody: parsedRes.fscChainOfCustody,
            complexity: parsedRes.complexity,
            productRestrict: parsedRes.productRestrict,
            containsCustOwnMaterial: parsedRes.containsCustOwnMaterial,
            containsNonStdMaterial: parsedRes.containsNonStdMaterial,
            hierarchy: parsedRes.hierarchy,
            features: parsedRes.features,
            nextFeature: null,
            parsedProductId: parsedRes.parsedProductId,
            parsedTimestamp: parsedRes.parsedTimestamp,
            parsedTimestampInEasternTime: parsedRes.parentParsedTimestampInEasternTime,
            requestTypeParsedProductId: parsedRes.requestTypeParsedProductId,
            basePrices: [],
            kitParent: parsedRes.kitParent,
            kitChildren: parsedRes.kitChildren,
            custom: parsedRes.custom,
            configuredIndicator: parsedRes.configuredIndicator,
            orderableInd: parsedRes.orderableInd,
            prop65: parsedRes.prop65,
            restrictions: parsedRes.productRestrictions,
            packageQty: parsedRes.packageQty,
            baseProductDescription: parsedRes.baseProductDescription,
            customPricing: [],
            royalties: parsedRes.productRoyalties,
        },
    };
}
