var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { InputFieldType } from 'models/Input/InputModels';
import Box from '@mui/material/Box';
import { debounce } from '@mui/material/utils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useMemo, useState } from 'react';
import { arrayMove } from 'utils/utils';
import { CPA_DEFAULT_STEP_NO, IMPLIED_FEATURE_TYPE } from 'utils/constants';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
export function DescriptionFeaturesTable(_a) {
    var _b;
    var _c = _a.showAddRow, showAddRow = _c === void 0 ? false : _c, _d = _a.showAddRowAction, showAddRowAction = _d === void 0 ? function () { return null; } : _d;
    var cpaProduct = useCpaProduct();
    var _e = useState(), deviationDesc = _e[0], setDeviationDesc = _e[1];
    useEffect(function () {
        var _a;
        setDeviationDesc((_a = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.data.currentProduct) === null || _a === void 0 ? void 0 : _a.deviationDesc);
    }, [(_b = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.data.currentProduct) === null || _b === void 0 ? void 0 : _b.deviationDesc]);
    var featureTableData = mapTableArrayToObject();
    var tableDataDefinition = {
        rowDecorator: {
            value: 'DEVIATED',
            hide: function (row) { return !row.devInd; },
        },
        columns: [
            {
                dataAttribute: 'rowNo',
                fieldType: InputFieldType.NUMBER,
                heading: 'Row',
                width: '100px',
                showHelpTooltip: true,
                validations: {
                    required: 'Required field.',
                    numberOnly: 'Invalid input. Only numbers allowed.',
                },
            },
            {
                dataAttribute: 'featureDescription',
                fieldType: InputFieldType.STRING,
                heading: 'Feature Name',
                width: '300px',
                showHelpTooltip: true,
                validations: {
                    required: 'Feature Name is required.',
                    maxLength: 255,
                },
            },
            {
                dataAttribute: 'optionCode',
                fieldType: InputFieldType.STRING,
                heading: 'Option Code',
                width: '200px',
                showHelpTooltip: true,
                validations: {
                    noSpaces: 'Invalid input. No spaces allowed.',
                    maxLength: 20,
                },
            },
            {
                dataAttribute: 'optionDescription',
                fieldType: InputFieldType.STRING,
                heading: 'Option Description',
                showHelpTooltip: true,
                validations: {
                    required: 'Option Description is required.',
                    maxLength: 500,
                },
            },
        ],
        addRowWhenNoData: false,
        showAddRow: showAddRow,
        showAddRowHandler: showAddRowAction,
        editHandler: function (key, newRow, oldRow) {
            var rowChanged = isRowChanged(newRow, oldRow);
            if (rowChanged) {
                var newRowNo = newRow.rowNo;
                newRow.devInd = true;
                delete newRow.rowNo;
                if (+key === newRowNo) {
                    cpaProduct.data.currentProduct.featureOptions[key - 1] = newRow;
                }
                else {
                    var newRowIndex = getNewRowIndex(newRowNo, key, cpaProduct.data.currentProduct.featureOptions.length);
                    arrayMove(cpaProduct.data.currentProduct.featureOptions, key - 1, newRowIndex);
                    resetFeatureSequences();
                }
                dispatchUpdateCurrentProduct();
            }
        },
        extraIcon: {
            icon: _jsx(RemoveCircleOutlineIcon, {}),
            handler: function (key) {
                cpaProduct.data.currentProduct.featureOptions[key - 1].devInd = false;
                dispatchUpdateCurrentProduct();
            },
            hide: function (row) { return !row.devInd; },
        },
        addHandler: function (newRow) {
            var _a;
            var newRowNo = newRow.rowNo;
            newRow.devInd = true;
            var newRowIndex = newRowNo > cpaProduct.data.currentProduct.featureOptions.length
                ? cpaProduct.data.currentProduct.featureOptions.length
                : newRowNo - 1;
            delete newRow.rowNo;
            (_a = cpaProduct.data.currentProduct.featureOptions) === null || _a === void 0 ? void 0 : _a.splice(newRowIndex, 0, appendDefaultValues(newRow, newRowIndex));
            resetFeatureSequences();
            dispatchUpdateCurrentProduct();
        },
        deleteProps: {
            deleteHandler: function (key) {
                var _a;
                (_a = cpaProduct.data.currentProduct.featureOptions) === null || _a === void 0 ? void 0 : _a.splice(Number(key) - 1, 1);
                dispatchUpdateCurrentProduct();
            },
            modalProps: {
                buttonProps: { confirmText: 'Delete Characteristic', cancelText: 'Keep Characteristic' },
                bodyTextArray: ['Are you sure you would like to delete this characteristic? This action is permanent.'],
                titleText: 'Delete Characteristic',
            },
        },
    };
    function isRowChanged(newRow, oldRow) {
        var rowChanged = false;
        if (newRow.featureDescription !== oldRow.featureDescription ||
            newRow.rowNo !== oldRow.rowNo ||
            newRow.optionCode !== oldRow.optionCode ||
            newRow.optionDescription !== oldRow.optionDescription) {
            rowChanged = true;
        }
        return rowChanged;
    }
    function resetFeatureSequences() {
        var _a;
        (_a = cpaProduct.data.currentProduct.featureOptions) === null || _a === void 0 ? void 0 : _a.forEach(function (el, i) {
            el.featureSequence = String((i + 1) * 100);
        });
    }
    function dispatchUpdateCurrentProduct() {
        cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProduct.data.currentProduct),
        });
    }
    function getNewRowIndex(newRowNo, oldRowNo, totalLength) {
        var newRowIndex;
        if (newRowNo > totalLength) {
            newRowIndex = totalLength - 1;
        }
        else if (newRowNo <= 0) {
            newRowIndex = 0;
        }
        else if (newRowNo > oldRowNo) {
            newRowIndex = newRowNo - 2;
        }
        else {
            newRowIndex = newRowNo - 1;
        }
        return newRowIndex;
    }
    function mapTableArrayToObject() {
        var _a, _b, _c;
        return ((_a = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.data.currentProduct) === null || _a === void 0 ? void 0 : _a.featureOptions)
            ? (_c = (_b = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.data.currentProduct) === null || _b === void 0 ? void 0 : _b.featureOptions) === null || _c === void 0 ? void 0 : _c.reduce(function (agg, el, index) {
                var _a;
                return (__assign(__assign({}, agg), (_a = {}, _a[index + 1] = __assign({ rowNo: index + 1 }, el), _a)));
            }, {})
            : {};
    }
    function appendDefaultValues(newRow, index) {
        var date = new Date();
        var epoch = date.getTime().toString();
        return __assign(__assign({}, newRow), { featureId: "implied-".concat(date.toTimeString().slice(0, 8).replaceAll(':', '')).concat(epoch.slice(epoch.length - 3, epoch.length)), step: CPA_DEFAULT_STEP_NO, type: IMPLIED_FEATURE_TYPE, skuSelection: false, nonStandardIndicator: false, multipleSelection: '0', optionSequence: 1, featureSequence: String((index + 1) * 100) });
    }
    var updateDeviationInContextDebouced = useMemo(function () {
        return debounce(function (updatedValue) {
            cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.dispatch({
                type: 'UPDATE_DEVIATION_DESC',
                payload: updatedValue,
            });
        }, 500);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (featureTableData && (_jsxs(_Fragment, { children: [(cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.data.currentProduct) && (_jsx(Box, __assign({ width: "25%" }, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, fieldValue: deviationDesc, labels: {
                        left: 'Deviations',
                        right: 'Required',
                    }, validations: {
                        required: 'Please enter value.',
                        maxLength: 2000,
                    }, fieldPlaceholderText: "Enter Deviation Notes Here", multipRows: 2, fieldValueUpdateHandler: function (val) {
                        setDeviationDesc(val);
                        updateDeviationInContextDebouced(val);
                    } }) }))), _jsx(PubTable, { def: tableDataDefinition, tableData: featureTableData })] })));
}
