var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { memo, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import { InputFieldType } from 'models/Input/InputModels';
import { noSpaces, numbersOnly, wholeNumbersOnly } from 'services/helperFunctions/handleRegex';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { materialTheme } from 'styles/theme/materialTheme';
import Checkbox from '@mui/material/Checkbox';
import { ErrorAdornment } from '../ErrorAdornment/ErrorAdornment';
import styles from './PubInputField.module.scss';
import { LoadingSpinner } from '../Loading/LoadingSpinner/LoadingSpinner';
var inputFieldsTheme = createTheme(__assign(__assign({}, materialTheme), { palette: __assign(__assign({}, materialTheme.palette), { text: {
            disabled: 'gray',
        } }) }));
export function PubInputFieldFun(_a) {
    var fieldType = _a.fieldType, fieldValue = _a.fieldValue, originalValue = _a.originalValue, fieldPlaceholderText = _a.fieldPlaceholderText, fieldOptions = _a.fieldOptions, helperText = _a.helperText, labels = _a.labels, _b = _a.fieldContainerWidthOverride, fieldContainerWidthOverride = _b === void 0 ? '100%' : _b, customErrorMsg = _a.customErrorMsg, multipRows = _a.multipRows, _c = _a.dirtyCheck, dirtyCheck = _c === void 0 ? false : _c, inProgress = _a.inProgress, _d = _a.uppercaseInput, uppercaseInput = _d === void 0 ? false : _d, _e = _a.isUnique, isUnique = _e === void 0 ? false : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, fontColorOverride = _a.fontColorOverride, validations = _a.validations, formInputName = _a.formInputName, _g = _a.showErrorAdornment, showErrorAdornment = _g === void 0 ? true : _g, switchLabel = _a.switchLabel, switchWidth = _a.switchWidth, fieldValueUpdateHandler = _a.fieldValueUpdateHandler, inError = _a.inError, defaultChecked = _a.defaultChecked, noRightMargin = _a.noRightMargin, selectorEnabled = _a.selectorEnabled, formInputRef = _a.formInputRef, fieldError = _a.fieldError, link = _a.link, linkText = _a.linkText, isCompleteProduct = _a.isCompleteProduct, borderFocus = _a.borderFocus, fontSize = _a.fontSize, linkAction = _a.linkAction, handleOpen = _a.handleOpen, fieldHasOption = _a.fieldHasOption, makeCaratTransparent = _a.makeCaratTransparent, hasLookup = _a.hasLookup;
    var _h = useState(), selectedValues = _h[0], setSelectedValues = _h[1];
    var _j = useState(false), selectAll = _j[0], setSelectAll = _j[1];
    useEffect(function () {
        if (InputFieldType.ENUMCHECKBOX) {
            setSelectAll(false);
            setSelectedValues([]);
        }
    }, [fieldOptions]);
    var handleCheckboxChange = function (event) {
        setSelectAll(false);
        setSelectedValues(event);
        fieldValueUpdateHandler(event);
    };
    var handleSelectAll = function () {
        setSelectAll(function (prevVal) {
            if (!prevVal) {
                fieldValueUpdateHandler(fieldOptions);
                setSelectedValues(fieldOptions);
            }
            else {
                fieldValueUpdateHandler([]);
                setSelectedValues([]);
            }
            return !prevVal;
        });
    };
    var fieldValueUpdated = "".concat(fieldValue !== null && fieldValue !== void 0 ? fieldValue : '');
    var isDirty = useRef(dirtyCheck === false);
    var _k = useState(undefined), errorMessage = _k[0], setErrorMessage = _k[1];
    var fireLinkAction = function () {
        linkAction === null || linkAction === void 0 ? void 0 : linkAction();
    };
    useEffect(function () {
        var errMsg;
        if (!validations) {
            errMsg = undefined;
        }
        else {
            if (validations.required) {
                if (fieldValueUpdated === undefined || fieldValueUpdated.trim().length === 0)
                    errMsg = validations.required;
            }
            if (validations.noSpaces) {
                if (!noSpaces(fieldValueUpdated)) {
                    errMsg = validations.noSpaces;
                }
            }
            if (validations.numberOnly) {
                if (fieldValueUpdated !== undefined && !numbersOnly(fieldValueUpdated.toString())) {
                    errMsg = validations.numberOnly;
                }
            }
            if (validations.wholeNumbersOnly) {
                if (fieldValueUpdated !== undefined && !wholeNumbersOnly(fieldValueUpdated.toString())) {
                    errMsg = validations.wholeNumbersOnly;
                }
            }
            if (validations.maxLength !== undefined) {
                var valueToCheck = hasLookup ? fieldValueUpdated === null || fieldValueUpdated === void 0 ? void 0 : fieldValueUpdated.split(' ')[0] : fieldValueUpdated;
                if (valueToCheck !== undefined && valueToCheck !== '' && valueToCheck.length > validations.maxLength) {
                    errMsg = "Invalid length. Max length ".concat(validations.maxLength, ".");
                }
            }
            if (customErrorMsg) {
                errMsg = customErrorMsg;
            }
            if (errMsg) {
                setErrorMessage(errMsg);
            }
            else if (validations.validate) {
                if (validations.validate instanceof Promise) {
                    validations.validate(fieldValueUpdated)
                        .then(function (rsp) {
                        setErrorMessage(rsp);
                    })
                        .catch(function (errRsp) {
                        setErrorMessage(errRsp);
                    });
                }
                else {
                    setErrorMessage(validations.validate(fieldValueUpdated));
                }
            }
            else {
                setErrorMessage(undefined);
            }
        }
    }, [customErrorMsg, fieldValueUpdated, validations, hasLookup]);
    useEffect(function () {
        inError === null || inError === void 0 ? void 0 : inError(!!errorMessage);
    }, [errorMessage, inError]);
    function handleEdits(value) {
        if (dirtyCheck && !isDirty.current) {
            isDirty.current = true;
        }
        fieldValueUpdateHandler(value);
    }
    var inputField = function () {
        switch (fieldType) {
            case InputFieldType.STRING:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: fieldError ? styles.leftError : styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(TextField, { size: "small", id: "stringTextField", fullWidth: true, value: fieldValueUpdated, helperText: helperText, placeholder: fieldPlaceholderText !== null && fieldPlaceholderText !== void 0 ? fieldPlaceholderText : undefined, error: (isDirty.current && !!errorMessage) || fieldError, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), disabled: disabled, multiline: !!multipRows, rows: multipRows, onInput: function (event) {
                                var value = uppercaseInput ? event.target.value.toUpperCase() : event.target.value;
                                handleEdits(value);
                            }, InputProps: {
                                endAdornment: inProgress ? (_jsx(CircularProgress, {})) : (isDirty.current &&
                                    errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }] }))),
                                sx: {
                                    color: !fieldError ? 'black' : 'rgb(208, 64, 33)',
                                    caretColor: makeCaratTransparent ? 'transparent' : '',
                                },
                                className: borderFocus ? 'Mui-focused' : '',
                            }, FormHelperTextProps: {
                                sx: { marginLeft: 0, marginRight: 0 },
                            }, name: formInputName, inputRef: formInputRef })] })));
            case InputFieldType.NUMBER:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: fieldError ? styles.leftError : styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(TextField, { id: "numberTextField", value: fieldValueUpdated, size: "small", type: "number", fullWidth: true, sx: { color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black' }, error: isDirty.current && !!errorMessage, disabled: disabled, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), onKeyDown: function (e) {
                                if ((fieldValueUpdated.includes('-') && e.key === '-') ||
                                    ['e', 'E', '+'].includes(e.key)) {
                                    e.preventDefault();
                                }
                            }, onInput: function (event) {
                                handleEdits("".concat(event.target.value === '' ? '' : +event.target.value));
                            }, InputProps: {
                                endAdornment: errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }] })),
                                sx: {
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        display: 'none',
                                    },
                                    '& input[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                },
                                className: borderFocus ? 'Mui-focused' : '',
                            }, inputRef: formInputRef })] })));
            case InputFieldType.TIME:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: fieldError ? styles.leftError : styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(TextField, { id: "timeTextField", value: fieldValueUpdated, type: "time", size: "small", fullWidth: true, sx: { color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black' }, error: isDirty.current && !!errorMessage, disabled: disabled, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), onChange: function (event) { return handleEdits(event.target.value); }, InputProps: {
                                endAdornment: errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }], horizontalShiftInPx: -14 })),
                                sx: { color: !fieldError ? 'black' : 'rgb(208, 64, 33)' },
                                className: borderFocus ? 'Mui-focused' : '',
                            }, name: formInputName })] })));
            case InputFieldType.DATE:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: fieldError ? styles.leftError : styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(TextField, { id: "dateTextField", value: fieldValueUpdated, type: "date", size: "small", fullWidth: true, sx: { color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black' }, error: isDirty.current && !!errorMessage, disabled: disabled, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), onChange: function (event) { return handleEdits(event.target.value); }, InputProps: {
                                endAdornment: isDirty.current && errorMessage && showErrorAdornment && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }], horizontalShiftInPx: -14 })),
                                sx: { color: !fieldError ? 'black' : 'rgb(208, 64, 33)' },
                                className: borderFocus ? 'Mui-focused' : '',
                            }, name: formInputName })] })));
            case InputFieldType.ENUM:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(FormControl, __assign({ fullWidth: true, error: isDirty.current && !!errorMessage, disabled: disabled, sx: {
                                color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black',
                            }, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), className: styles.selectOption }, { children: fieldOptions && fieldOptions.length > 0 ? (_jsx(Select, __assign({ MenuProps: {
                                    sx: { li: { height: '2.25rem' } },
                                }, size: "small", displayEmpty: !!fieldPlaceholderText, renderValue: function (value) {
                                    return (value === null || value === void 0 ? void 0 : value.trim().length) > 0 ? value : fieldPlaceholderText;
                                }, className: fieldHasOption ? 'Mui-focused' : 'none', value: fieldValueUpdated, placeholder: fieldPlaceholderText !== null && fieldPlaceholderText !== void 0 ? fieldPlaceholderText : undefined, onChange: function (event) {
                                    handleEdits(event.target.value);
                                }, endAdornment: isDirty.current &&
                                    errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }], horizontalShiftInPx: -14 })) }, { children: (fieldOptions.includes(originalValue) || (!isUnique && !selectorEnabled)
                                    ? fieldOptions
                                    : __spreadArray(__spreadArray([], fieldOptions, true), [originalValue], false).includes(fieldValueUpdated)
                                        ? __spreadArray(__spreadArray([], fieldOptions, true), [originalValue], false) : __spreadArray(__spreadArray([], fieldOptions, true), [fieldValueUpdated, originalValue], false)).map(function (o) { return (_jsx(MenuItem, __assign({ value: o }, { children: o }), o)); }) }))) : (_jsx(Select, __assign({ onOpen: function (e) { return handleOpen === null || handleOpen === void 0 ? void 0 : handleOpen(e); }, MenuProps: {
                                    sx: { li: { height: '2.25rem' } },
                                }, size: "small", displayEmpty: !!fieldPlaceholderText, renderValue: function (value) {
                                    return (value === null || value === void 0 ? void 0 : value.trim().length) > 0 ? value : fieldPlaceholderText;
                                }, className: "Mui-focused", value: fieldValueUpdated, placeholder: fieldPlaceholderText !== null && fieldPlaceholderText !== void 0 ? fieldPlaceholderText : undefined, onChange: function (event) {
                                    handleEdits(event.target.value);
                                }, endAdornment: isDirty.current &&
                                    errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }], horizontalShiftInPx: -14 })) }, { children: _jsx(MenuItem, { children: _jsx(LoadingSpinner, { indicatorSize: 2 }) }) }))) }))] })));
            case InputFieldType.ENUMCHECKBOX:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(FormControl, __assign({ fullWidth: true, error: isDirty.current && !!errorMessage, disabled: disabled, sx: { color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black' }, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), className: styles.selectOption }, { children: fieldOptions && fieldOptions.length > 0 && (_jsxs(Select, __assign({ MenuProps: {
                                    sx: { li: { height: '2.25rem' } },
                                }, size: "small", displayEmpty: !!fieldPlaceholderText, placeholder: fieldPlaceholderText !== null && fieldPlaceholderText !== void 0 ? fieldPlaceholderText : undefined, endAdornment: isDirty.current &&
                                    errorMessage && (_jsx(ErrorAdornment, { fontSize: "14px", placement: "top", errors: [{ condition: true, message: errorMessage }], horizontalShiftInPx: -14 })), multiple: true, value: selectedValues || [], onChange: function (event) { return handleCheckboxChange(event.target.value); }, renderValue: function (selected) {
                                    if (selected.length === 0) {
                                        return fieldPlaceholderText;
                                    }
                                    if (selected.length === fieldOptions.length) {
                                        return 'All Selected';
                                    }
                                    return "".concat(selected.length, " selected");
                                } }, { children: [_jsxs(MenuItem, __assign({ onClick: handleSelectAll }, { children: [_jsx(Checkbox, { checked: (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length) === fieldOptions.length }), "Select All Dates"] }), "select-all"), (fieldOptions.includes(originalValue) || !isUnique
                                        ? fieldOptions
                                        : __spreadArray(__spreadArray([], fieldOptions, true), [originalValue], false).includes(selectedValues)
                                            ? __spreadArray(__spreadArray([], fieldOptions, true), [originalValue], false) : __spreadArray(__spreadArray([], fieldOptions, true), [selectedValues, originalValue], false)).map(function (o, index) { return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    _jsxs(MenuItem, __assign({ value: o }, { children: [_jsx(Checkbox, { checked: (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.includes(o)) || selectAll }), o] }), index)); })] }))) }))] })));
            case InputFieldType.RADIO:
                return (_jsxs(Box, __assign({ className: styles.container, sx: { width: fieldContainerWidthOverride } }, { children: [labels && (_jsxs(Box, __assign({ className: styles.textFieldHeader }, { children: [_jsx(Typography, __assign({ className: styles.left }, { children: (labels === null || labels === void 0 ? void 0 : labels.left) ? labels.left : '' })), _jsx(Typography, __assign({ className: styles.right }, { children: (labels === null || labels === void 0 ? void 0 : labels.right) ? labels.right : '' }))] }))), _jsx(FormControl, __assign({ className: styles.formControl, disabled: disabled, sx: { color: fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : 'black' }, required: !!(validations === null || validations === void 0 ? void 0 : validations.required), fullWidth: true }, { children: _jsx(RadioGroup, __assign({ className: styles.radioGroup, value: fieldValueUpdated, onChange: function (event) { return handleEdits(event.target.value); }, name: "radioVal" }, { children: fieldOptions &&
                                    fieldOptions.length > 0 &&
                                    fieldOptions.map(function (o) { return (_jsx(FormControlLabel, { value: o, label: o, control: _jsx(Radio, { size: "small" }) }, o)); }) })) }))] })));
            case InputFieldType.SWITCH:
                return (_jsx(FormControlLabel, { style: {
                        marginRight: noRightMargin ? '0px' : '20px',
                        width: noRightMargin ? 'fit-content' : "".concat(switchWidth, "px"),
                    }, control: _jsx(Switch, { onClick: fieldValueUpdateHandler, defaultChecked: defaultChecked }), label: switchLabel }));
            default: // READONLY
                return (_jsx(Box, __assign({ className: styles.readOnlyContainer }, { children: inProgress ? (_jsx(CircularProgress, { size: "24px" })) : (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: styles.readOnlyLabel, fontStyle: fieldValueUpdated === '' && fieldPlaceholderText ? 'italic' : 'normal', color: (fontColorOverride !== null && fontColorOverride !== void 0 ? fontColorOverride : errorMessage) ? 'rgb(208, 122, 44)' : 'black', fontFamily: errorMessage
                                    ? 'UntitledSansMedium, sans-serif, system-ui'
                                    : 'UntitledSans, sans-serif, system-ui', sx: { fontSize: fontSize ? "".concat(fontSize, "px") : 'inherit' } }, { children: errorMessage
                                    ? "".concat(errorMessage)
                                    : fieldValueUpdated === '' && fieldPlaceholderText
                                        ? fieldPlaceholderText
                                        : fieldValueUpdated })), link && fieldValue !== '' && isCompleteProduct && (_jsxs(Typography, __assign({ onClick: function () { return fireLinkAction === null || fireLinkAction === void 0 ? void 0 : fireLinkAction(); } }, { children: ["(", _jsx("span", __assign({ style: {
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                        } }, { children: linkText })), ")"] })))] })) })));
        }
    };
    return _jsx(ThemeProvider, __assign({ theme: inputFieldsTheme }, { children: inputField() }));
}
export var PubInputField = memo(PubInputFieldFun);
