var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SyncAlt from '@mui/icons-material/SyncAlt';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { infoSelect, productSyncToDateLake } from 'services/api/product-service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getFilteredProducts } from 'services/api/search-filter-build-products';
import { DATALAKE_SCOPES, PRODUCT_CLASS_ALLOWED, MAXIMUM_PRODUCT_FEATURE_LIMIT } from 'utils/constants';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { searchForParentByChildId } from 'services/api/search-kits';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { CatalogHierarchies } from './CatalogHierarchies/CatalogHierarchies';
import { ProductCodes } from './ProductCodes/ProductCodes';
import { ProductDetails } from './ProductDetails/ProductDetails';
import { CustomDetails } from './CustomDetails/CustomDetails';
import { OptionsInfoTab } from './OptionsInfoTab/OptionsInfoTab';
import styles from './ProductInfoPageV2.module.scss';
export function ProductInfoPageV2() {
    var _this = this;
    var _a, _b;
    var loaderData = useLoaderData();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var userHasDataLakePrivileges = useCheckAuthScope(DATALAKE_SCOPES);
    var throwErrorAlert = useCallback(function () {
        setSnackbarAlert({
            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
            type: 'error',
        });
    }, [setSnackbarAlert]);
    useEffect(function () {
        if (loaderData.isError) {
            throwErrorAlert();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var searchParams = loaderData.searchParams;
    var navigate = useNavigate();
    var handleBackwardsNavigate = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (searchParams.origin) {
                switch (searchParams.origin) {
                    case 'simple':
                        navigate("/search-v2/simple/product");
                        break;
                    case 'advanced':
                        navigate('/search-v2/advanced');
                        break;
                    case 'hierarchy':
                        navigate('/search-v2/hierarchy');
                        break;
                    default:
                        break;
                }
            }
            return [2 /*return*/];
        });
    }); };
    var showCustom = useMemo(function () { var _a, _b, _c; return PRODUCT_CLASS_ALLOWED.includes((_c = (_b = (_a = loaderData.infoRsp.body.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.productClass) !== null && _c !== void 0 ? _c : ''); }, [(_b = (_a = loaderData.infoRsp.body.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.productClass]);
    var linkToBuild = {
        label: 'Use for Product Build',
    };
    var syncToSnowFlake = {
        label: 'Sync to SnowFlake',
    };
    function handleLinkClick() {
        return __awaiter(this, void 0, void 0, function () {
            var rsp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, getFilteredProducts(searchParams.prefix, searchParams.date, searchParams.time, searchParams.channel)];
                    case 1:
                        rsp = _a.sent();
                        navigate("/product-build-v2/build?productNbr=".concat(searchParams.prefix, "&date=").concat(searchParams.date, "&time=").concat(searchParams.time, "&channel=").concat(searchParams.channel, "&pid=").concat(searchParams.id, "&productVersion=").concat(searchParams.version), {
                            state: {
                                data: rsp,
                                totalCount: rsp.data.resultsCount,
                                selectedProdVersion: searchParams.version,
                                navigatedFrom: 'productInfo',
                            },
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleSnowflakeSync() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var processingTime, snowflakeSyncRequest, infoRsp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        processingTime = new Date().getTime();
                        snowflakeSyncRequest = {
                            id: "".concat(processingTime, "_").concat(searchParams.id),
                            'detail-type': 'PRODUCT_VERSION_UPDATED',
                            source: 'ProductInfoPageV2Sync',
                            time: processingTime,
                            detail: {
                                productId: Number((_a = searchParams.id.match(/\d+/)) === null || _a === void 0 ? void 0 : _a[0]),
                                productVersion: searchParams.version,
                            },
                        };
                        return [4 /*yield*/, productSyncToDateLake(snowflakeSyncRequest)];
                    case 1:
                        infoRsp = _b.sent();
                        if (infoRsp.status === 200) {
                            setSnackbarAlert({
                                message: 'Product successfully published to datalake sync queue',
                                type: 'success',
                            });
                        }
                        else {
                            setSnackbarAlert({
                                message: 'Product unable to publish to datalake sync queue. Please check producer lambda',
                                type: 'error',
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    return (_jsx(Box, __assign({ sx: { margin: '0 40px' } }, { children: !isLoading ? (_jsxs(Box, { children: [loaderData.infoRsp.status === 200 && (_jsxs(_Fragment, { children: [_jsxs(Button, __assign({ onClick: function () { return handleBackwardsNavigate(); }, sx: { width: '100px' } }, { children: [_jsx(KeyboardBackspaceIcon, {}), _jsx("span", __assign({ style: {
                                        marginLeft: '20px',
                                        fontFamily: 'UntitledSansMedium, sans-serif, system-ui',
                                        fontSize: '16px',
                                    } }, { children: "Back" }))] })), _jsx("hr", { className: styles.hr }), _jsxs(Box, __assign({ className: styles.container }, { children: [_jsxs(Box, __assign({ className: styles.headerContainer }, { children: [_jsx(ProductDetails, { infoResponse: loaderData.infoRsp.body, pid: searchParams.id, version: searchParams.version, kitParentArray: loaderData.kitParentArray }), _jsx(ProductCodes, { infoResponse: loaderData.infoRsp.body }), _jsxs(Box, __assign({ className: styles.catalogContainer }, { children: [_jsx(CatalogHierarchies, { infoResponse: loaderData.infoRsp.body }), _jsx("hr", { className: styles.hr }), _jsx(Box, __assign({ style: { height: '60px', display: 'flex' } }, { children: _jsxs(Button, __assign({ className: styles.lookUpButton, onClick: function () { return handleLinkClick(); } }, { children: [_jsx(EditIcon, { sx: { height: '18px', width: '18px' } }), _jsxs("span", __assign({ style: { paddingLeft: '8px' } }, { children: [" ", linkToBuild.label] }))] })) })), userHasDataLakePrivileges && (_jsx(Box, __assign({ style: { height: '60px', display: 'flex' } }, { children: _jsxs(Button, __assign({ className: styles.lookUpButton, onClick: function () { return handleSnowflakeSync(); } }, { children: [_jsx(SyncAlt, { sx: { height: '18px', width: '18px' } }), _jsxs("span", __assign({ style: { paddingLeft: '8px' } }, { children: [" ", syncToSnowFlake.label] }))] })) })))] }))] })), showCustom ? _jsx(CustomDetails, { infoResponse: loaderData.infoRsp.body }) : null] })), _jsx(OptionsInfoTab, { featuresData: loaderData.infoFeatureRsp, infoResponse: loaderData.infoRsp.body })] })), loaderData.infoRsp.status === 400 && (_jsx(Box, __assign({ style: { textAlign: 'center', marginTop: '25px' } }, { children: loaderData.infoRsp.body.message })))] })) : (_jsx(LoadingSpinner, { indicatorSize: 10 })) })));
}
export function ProductInfoPageV2Loader(_a) {
    var _b, e_1, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var request = _a.request;
    return __awaiter(this, void 0, void 0, function () {
        var isError, sParams, searchParams, infoRsp, kitParentArray, listOfParents, featuresList, promises, infoFeatureRsp, _p, featuresList_1, featuresList_1_1, k, e_1_1, infoFeatureMap_1;
        return __generator(this, function (_q) {
            switch (_q.label) {
                case 0:
                    isError = false;
                    sParams = new URL(request.url).searchParams;
                    searchParams = {
                        prefix: sParams.get('prefix'),
                        date: sParams.get('date'),
                        time: sParams.get('time'),
                        channel: sParams.get('channel'),
                        id: sParams.get('pid'),
                        version: sParams.get('productVersion'),
                        parsedProductId: sParams.get('parsedProductId'),
                        origin: sParams.get('origin'),
                    };
                    sessionStorage.removeItem('latestValidate');
                    sessionStorage.setItem('productVersion', (_e = searchParams.version) !== null && _e !== void 0 ? _e : '');
                    return [4 /*yield*/, infoSelect({
                            productVersion: (_f = searchParams.version) !== null && _f !== void 0 ? _f : '',
                            productId: (_g = searchParams.id) !== null && _g !== void 0 ? _g : '',
                            processingDate: "".concat(searchParams.date, "T").concat(searchParams.time, ".000Z"),
                        }, 'header,features,featureswithfabricflag,hierarchy')];
                case 1:
                    infoRsp = _q.sent();
                    if (infoRsp.status >= 500) {
                        isError = true;
                    }
                    kitParentArray = [];
                    if (!!((_j = (_h = infoRsp.body.details) === null || _h === void 0 ? void 0 : _h.header) === null || _j === void 0 ? void 0 : _j.kitParent)) return [3 /*break*/, 3];
                    return [4 /*yield*/, searchForParentByChildId(searchParams.id, searchParams.date)];
                case 2:
                    listOfParents = _q.sent();
                    kitParentArray = listOfParents;
                    _q.label = 3;
                case 3:
                    featuresList = (_l = (_k = infoRsp.body.details) === null || _k === void 0 ? void 0 : _k.features) !== null && _l !== void 0 ? _l : [];
                    promises = [];
                    if (!(featuresList.length <= MAXIMUM_PRODUCT_FEATURE_LIMIT)) return [3 /*break*/, 17];
                    if (!!isError) return [3 /*break*/, 15];
                    _q.label = 4;
                case 4:
                    _q.trys.push([4, 9, 10, 15]);
                    _p = true, featuresList_1 = __asyncValues(featuresList);
                    _q.label = 5;
                case 5: return [4 /*yield*/, featuresList_1.next()];
                case 6:
                    if (!(featuresList_1_1 = _q.sent(), _b = featuresList_1_1.done, !_b)) return [3 /*break*/, 8];
                    _d = featuresList_1_1.value;
                    _p = false;
                    try {
                        k = _d;
                        if (!k.hasFabric) {
                            promises.push(infoSelect({
                                featureId: k.featureId,
                                productVersion: (_m = searchParams.version) !== null && _m !== void 0 ? _m : '',
                            }, 'options,feature', false));
                        }
                        else {
                            promises.push(infoSelect({
                                featureId: k.featureId,
                                productVersion: (_o = searchParams.version) !== null && _o !== void 0 ? _o : '',
                            }, 'feature', true));
                        }
                    }
                    finally {
                        _p = true;
                    }
                    _q.label = 7;
                case 7: return [3 /*break*/, 5];
                case 8: return [3 /*break*/, 15];
                case 9:
                    e_1_1 = _q.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 15];
                case 10:
                    _q.trys.push([10, , 13, 14]);
                    if (!(!_p && !_b && (_c = featuresList_1.return))) return [3 /*break*/, 12];
                    return [4 /*yield*/, _c.call(featuresList_1)];
                case 11:
                    _q.sent();
                    _q.label = 12;
                case 12: return [3 /*break*/, 14];
                case 13:
                    if (e_1) throw e_1.error;
                    return [7 /*endfinally*/];
                case 14: return [7 /*endfinally*/];
                case 15: return [4 /*yield*/, Promise.all(promises)];
                case 16:
                    infoFeatureRsp = _q.sent();
                    infoFeatureMap_1 = infoFeatureRsp.map(function (ifri) { return ifri.body.details.feature; });
                    infoFeatureRsp.forEach(function (item) {
                        var _a, _b;
                        if ((_b = (_a = item.body.details) === null || _a === void 0 ? void 0 : _a.feature) === null || _b === void 0 ? void 0 : _b.nonStandardIndicator) {
                            var nsoItem = item.body.details.feature;
                            var deps = Object.values(nsoItem === null || nsoItem === void 0 ? void 0 : nsoItem.dependencyRules);
                            var keyArray_1 = [];
                            deps.forEach(function (dep) {
                                var dependencies = Object.entries(dep.dependencies);
                                var depConfig = {
                                    featureId: dependencies[0][0],
                                    optionCode: dependencies[0][1],
                                };
                                keyArray_1.push(depConfig);
                            });
                            var listOfGroupCodes_1 = [];
                            keyArray_1.forEach(function (dep) {
                                var i = infoFeatureMap_1.findIndex(function (ifmi) { return dep.featureId === ifmi.featureId; });
                                if (i === -1) {
                                    return;
                                }
                                if (infoFeatureMap_1[i].options[dep.optionCode].nonStandardGroupCode) {
                                    listOfGroupCodes_1.push(infoFeatureMap_1[i].options[dep.optionCode].nonStandardGroupCode);
                                }
                            });
                            item.body.details.feature.nonStandardGroupCodes = listOfGroupCodes_1;
                        }
                    });
                    _q.label = 17;
                case 17: return [2 /*return*/, { infoRsp: infoRsp, infoFeatureRsp: infoFeatureRsp, searchParams: searchParams, kitParentArray: kitParentArray, isError: isError }];
            }
        });
    });
}
