var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { GSA_CHANNEL } from 'utils/constants';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { CustomEngSite, CustomEngStatus, CustomQuoteStatus } from '@millerknoll/pub-api-schema';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { useLookupSearch } from 'hooks/useLookupSearch';
import { LookupType } from 'services/api/search-service';
import { BUSINESS_UNIT_CODE_VALUE_MAPPINGS, EXTRA_DATA_FIELD_NAMES, DEFAULT_FSCCHAINOFCUSTODY, FSC_TYPE, BRAND_CODE, COMPLEXITY, } from 'utils/cpa-utils';
export function CpaExtraDataCustomFieldsSpecials() {
    var _a;
    var _b, _c;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _d = useState(buildFieldValues(undefined)), fieldValues = _d[0], setFieldValues = _d[1];
    var weightClassCodeValidate = useLookupSearch(LookupType.WeightClassCode, (_b = fieldValues[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE]) === null || _b === void 0 ? void 0 : _b.value);
    var productFunctionCodeValidate = useLookupSearch(LookupType.ProductFunctionCode, (_c = fieldValues[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE]) === null || _c === void 0 ? void 0 : _c.value);
    /**
     * Holds Column Definition and Initial Values of the Column
     */
    var colDef = {
        title: { displayText: 'Extra Data:' },
        rowGap: '24px',
        showActions: true,
        preventReadOnly: true,
        fields: (_a = {},
            _a[EXTRA_DATA_FIELD_NAMES.LEAD_TIME] = {
                fieldName: 'Lead Time:',
                displaySeq: 0,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: ['Assigned'],
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.GSA] = {
                fieldName: 'GSA:',
                displaySeq: 1,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasTextField: true,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.FSC_TYPE] = {
                fieldName: 'Forest Certification:',
                displaySeq: 2,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(FSC_TYPE),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.COMPLEXITY] = {
                fieldName: 'Manufacturing Complexity:',
                displaySeq: 3,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(COMPLEXITY),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE] = {
                fieldName: 'Weight Class Code:',
                displaySeq: 4,
                input: {
                    type: InputFieldType.STRING,
                    validations: {
                        maxLength: 10,
                    },
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE] = {
                fieldName: 'Product Func. Code:',
                displaySeq: 5,
                input: {
                    type: InputFieldType.STRING,
                    validations: {
                        maxLength: 50,
                    },
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_FIELD_NAMES.QUOTE_STATUS] = {
                fieldName: 'Custom Quote Status:',
                displaySeq: 6,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(CustomQuoteStatus),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.ENG_STATUS] = {
                fieldName: 'Custom Engineering Status:',
                displaySeq: 7,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(CustomEngStatus),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.PROPRIETARY_IND] = {
                fieldName: 'Custom Proprietary Indicator:',
                displaySeq: 8,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.ENG_SITE] = {
                fieldName: 'Custom Engineering Site:',
                displaySeq: 9,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: __spreadArray([''], Object.values(CustomEngSite), true),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.BUSINESS_UNIT_CODE] = {
                fieldName: 'Business Unit:',
                displaySeq: 10,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.keys(BUSINESS_UNIT_CODE_VALUE_MAPPINGS),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.BRAND_CODE] = {
                fieldName: 'Brand:',
                displaySeq: 11,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(BRAND_CODE),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a),
    };
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE].loading = weightClassCodeValidate.loading;
            cur[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE].customErrorMsg = weightClassCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [weightClassCodeValidate.loading, weightClassCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE].loading = productFunctionCodeValidate.loading;
            cur[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE].customErrorMsg = productFunctionCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [productFunctionCodeValidate.loading, productFunctionCodeValidate.errorMessage]);
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        var initialValues = buildFieldValues(cpaProductContext.data.currentProduct);
        setFieldValues(initialValues);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct]);
    var handleAction = function (actionType) {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct));
        }
    };
    var postConfirmationUpdate = function () {
        updateCurrentProductCustomFields(colDef);
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    function updateCurrentProductCustomFields(def) {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct) {
            return;
        }
        Object.keys(fieldValues).forEach(function (propertyName) {
            var _a;
            if (cpaProductContext.data.currentProduct) {
                var type = def.fields[propertyName].input.type;
                var currVal_1 = fieldValues[propertyName].value;
                if (type === InputFieldType.STRING ||
                    propertyName === EXTRA_DATA_FIELD_NAMES.COMPLEXITY ||
                    propertyName === EXTRA_DATA_FIELD_NAMES.BRAND_CODE) {
                    cpaProductContext.data.currentProduct[propertyName] = currVal_1;
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.FSC_TYPE) {
                    cpaProductContext.data.currentProduct[propertyName] = currVal_1;
                    if (currVal_1 === 'FSC Mix No' || currVal_1 === null) {
                        cpaProductContext.data.currentProduct.fscChainOfCustody = null;
                    }
                    else {
                        cpaProductContext.data.currentProduct.fscChainOfCustody = DEFAULT_FSCCHAINOFCUSTODY;
                    }
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE) {
                    cpaProductContext.data.currentProduct[propertyName] = currVal_1;
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE) {
                    if (currVal_1) {
                        var _b = currVal_1.split(' '), code = _b[0], descriptionParts = _b.slice(1);
                        var description = descriptionParts.join(' ');
                        cpaProductContext.data.currentProduct[propertyName] = code;
                        cpaProductContext.data.currentProduct.productFunctionDesc = description;
                    }
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.GSA) {
                    updateChannels(cpaProductContext.data.currentProduct, currVal_1);
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.QUOTE_STATUS) {
                    cpaProductContext.data.currentProduct[propertyName] = Object.values(CustomQuoteStatus).find(function (v) { return v === currVal_1; });
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.ENG_STATUS) {
                    cpaProductContext.data.currentProduct[propertyName] = Object.values(CustomEngStatus).find(function (v) { return v === currVal_1; });
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.ENG_SITE) {
                    cpaProductContext.data.currentProduct[propertyName] = Object.values(CustomEngSite).find(function (v) { return v === currVal_1; });
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.BUSINESS_UNIT_CODE) {
                    var busUnitCode_1 = Object.keys(BUSINESS_UNIT_CODE_VALUE_MAPPINGS).find(function (k) { return k === currVal_1; });
                    cpaProductContext.data.currentProduct[propertyName] = busUnitCode_1;
                    (_a = cpaProductContext.data.currentProduct.channels) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                        el.busUnitCd = busUnitCode_1;
                    });
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.PROPRIETARY_IND) {
                    cpaProductContext.data.currentProduct[propertyName] =
                        currVal_1 === 'Yes';
                }
            }
        });
    }
    return (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        } }));
}
function updateChannels(cpaProduct, newValue) {
    if (!cpaProduct.channels) {
        cpaProduct.channels = [];
    }
    if (newValue === 'Yes') {
        var gsaChannel = cpaProduct.channels.find(function (el) { return el.code === GSA_CHANNEL; });
        if (!gsaChannel) {
            cpaProduct.channels.push({
                prcBkPrmtbl: true,
                sifPrmtbl: true,
                cetPrmtbl: true,
                m2mInd: false,
                code: GSA_CHANNEL,
            });
        }
    }
    else {
        cpaProduct.channels = cpaProduct.channels.filter(function (el) { return el.code !== GSA_CHANNEL; });
    }
}
var buildFieldValues = function (currentProduct) {
    var _a;
    var _b;
    return (_a = {},
        _a[EXTRA_DATA_FIELD_NAMES.LEAD_TIME] = {
            value: (currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.minLeadtime) ? 'Assigned' : undefined,
        },
        _a[EXTRA_DATA_FIELD_NAMES.GSA] = {
            value: ((_b = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.channels) === null || _b === void 0 ? void 0 : _b.find(function (el) { return el.code === GSA_CHANNEL; })) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_FIELD_NAMES.FSC_TYPE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.fscType,
        },
        _a[EXTRA_DATA_FIELD_NAMES.COMPLEXITY] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.complexity,
        },
        _a[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.weightClassCode,
        },
        _a[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.productFunctionCode,
        },
        _a[EXTRA_DATA_FIELD_NAMES.QUOTE_STATUS] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.quoteStatus,
        },
        _a[EXTRA_DATA_FIELD_NAMES.ENG_STATUS] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.engStatus,
        },
        _a[EXTRA_DATA_FIELD_NAMES.PROPRIETARY_IND] = {
            value: (currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.proprietaryInd) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_FIELD_NAMES.ENG_SITE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.engSite,
        },
        _a[EXTRA_DATA_FIELD_NAMES.BRAND_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.brandCode,
        },
        _a[EXTRA_DATA_FIELD_NAMES.BUSINESS_UNIT_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.businessUnitCode,
        },
        _a);
};
