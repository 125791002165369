var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { InputFieldType } from 'models/Input/InputModels';
import { PubInputField } from '../PubInputField/PubInputField';
import styles from './LabelWithInput.module.scss';
import { LookupInputs } from '../LookupModal/LookupInputs';
export function LabelWithInputFun(_a) {
    var _b;
    var fieldProperty = _a.fieldProperty, fieldName = _a.fieldName, fieldValue = _a.fieldValue, fieldType = _a.fieldType, customErrorMsg = _a.customErrorMsg, fieldOptions = _a.fieldOptions, fieldNameWidth = _a.fieldNameWidth, fieldPlaceholderText = _a.fieldPlaceholderText, hasLookup = _a.hasLookup, validations = _a.validations, informationalText = _a.informationalText, lineHeight = _a.lineHeight, minHeight = _a.minHeight, loading = _a.loading, _c = _a.disabled, disabled = _c === void 0 ? false : _c, link = _a.link, linkText = _a.linkText, isCompleteProduct = _a.isCompleteProduct, linkAction = _a.linkAction, inError = _a.inError, updateFieldValue = _a.updateFieldValue, topBottomPadding = _a.topBottomPadding;
    var _d = useState(false), isModalOpen = _d[0], setIsModalOpen = _d[1];
    function handleOpenModal() {
        setIsModalOpen(true);
    }
    var updateCode = useCallback(function (row) {
        setIsModalOpen(false);
        var valueToUpdate = row.description ? "".concat(row.code, " ").concat(row.description) : "".concat(row.code);
        updateFieldValue(valueToUpdate);
    }, [updateFieldValue]);
    var fireLinkAction = function () {
        linkAction === null || linkAction === void 0 ? void 0 : linkAction();
    };
    return (_jsxs(Box, __assign({ className: styles.container, sx: {
            height: lineHeight !== null && lineHeight !== void 0 ? lineHeight : 'initial',
            minHeight: minHeight !== null && minHeight !== void 0 ? minHeight : 'initial',
            alignItems: informationalText !== undefined ? 'flex-start' : 'center',
            paddingTop: topBottomPadding !== null && topBottomPadding !== void 0 ? topBottomPadding : 'initial',
            width: '100%',
        } }, { children: [_jsx(Typography, __assign({ className: styles.label, sx: {
                    maxWidth: (_b = "".concat(fieldNameWidth, " !important")) !== null && _b !== void 0 ? _b : 'initial',
                    width: minHeight ? '55% !important' : 'initial',
                    minWidth: fieldNameWidth,
                } }, { children: fieldName })), _jsxs(Box, __assign({ className: styles.inputInformationContainer }, { children: [_jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Box, __assign({ width: fieldType !== InputFieldType.RADIO ? '100%' : 'auto' }, { children: _jsx(PubInputField, { fieldType: fieldType, fieldValue: fieldValue, customErrorMsg: customErrorMsg, fieldPlaceholderText: fieldPlaceholderText, fieldOptions: fieldOptions !== null && fieldOptions !== void 0 ? fieldOptions : [], fieldValueUpdateHandler: function (value) {
                                        updateFieldValue(value);
                                    }, validations: validations, inProgress: loading, disabled: disabled, inError: inError, link: link, linkText: linkText, linkAction: function () { return fireLinkAction === null || fireLinkAction === void 0 ? void 0 : fireLinkAction(); }, isCompleteProduct: isCompleteProduct, fontSize: 13, hasLookup: hasLookup }) })), hasLookup && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ className: styles.lookUpButton, disabled: disabled, onClick: function () { return handleOpenModal(); } }, { children: "Look Up" })), isModalOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(LookupInputs, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, fieldProperty: fieldProperty, fieldValue: fieldValue, changeCodeHandler: updateCode }) })))] }))] })), informationalText !== undefined && (_jsx(Box, __assign({ className: styles.informationalText }, { children: _jsx(PubInputField, { fieldType: InputFieldType.READONLY, fieldValue: informationalText, fieldValueUpdateHandler: function () { return null; }, fontColorOverride: "rgb(208, 122, 44)", fontSize: 13 }) })))] }))] })));
}
export var LabelWithInput = memo(LabelWithInputFun);
