var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StarsIcon from '@mui/icons-material/Stars';
import { useCallback, useMemo, useState } from 'react';
import { DerivedFeaturesModal } from 'components/Shared/DerivedFeaturesModal/DerivedFeaturesModal';
import { B2B_CHANNEL, DEFAULT_LANGUAGE_CODE, GSA_CHANNEL } from 'utils/constants';
import { OptionRoyaltyRestrictionModal } from 'components/Shared/OptionRoyaltyRestrictionModal/OptionRoyaltyRestrictionModal';
import { FabricOptionsInput } from 'components/ProductBuildNew/Features/Fabrics/FabricOptionsInput';
import { NonStdOptionsInput } from 'components/ProductBuildNew/Features/NonStandard/NonStdOptionsInput';
import { useSearchParams } from 'react-router-dom';
import { infoSelect } from 'services/api/product-service';
import { getNonStandardOptions } from 'services/api/search-nso';
import styles from './ProductInfoPageV2TableRow.module.scss';
export function ProductInfoPageV2TableRow(_a) {
    var _this = this;
    var _b, _c, _d, _e;
    var rowFeature = _a.rowFeature, index = _a.index, showOptionalColumns = _a.showOptionalColumns, selectedPromoColumn = _a.selectedPromoColumn, derivedOptions = _a.derivedOptions, userHasRoyaltyPrivileges = _a.userHasRoyaltyPrivileges, businessUnitCode = _a.businessUnitCode;
    var searchParams = useSearchParams()[0];
    var _f = useState(), nsOptions = _f[0], setNsoOptions = _f[1];
    var _g = useState(), nsMetadata = _g[0], setNsMetadata = _g[1];
    var NA_CONTRACT_CHANNEL = 'NA Contract';
    var NA_CONTRACT_CHANNEL_CAP = 'NA CONTRACT';
    var isFabric = useMemo(function () {
        var _a;
        if (rowFeature.hasFabric) {
            return true;
        }
        if (rowFeature.options) {
            var entries = Object.values(rowFeature.options);
            return ((_a = entries[0]) === null || _a === void 0 ? void 0 : _a.type) === '3';
        }
        return false;
    }, [rowFeature]);
    var getNsos = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var req, rsp, responseData, sortByCode, sortByGroupCode;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    req = {
                        featureId: rowFeature.featureId,
                        effectiveDate: "".concat(searchParams.get('date'), "T").concat(searchParams.get('time'), ".000Z"),
                        groupCodes: rowFeature.nonStandardGroupCodes,
                        productVersion: (_a = searchParams.get('productVersion')) !== null && _a !== void 0 ? _a : '',
                        pageSize: 500,
                        businessUnitCode: businessUnitCode !== null && businessUnitCode !== void 0 ? businessUnitCode : '',
                    };
                    return [4 /*yield*/, getNonStandardOptions(req)];
                case 1:
                    rsp = _e.sent();
                    responseData = rsp.body.data;
                    if (rowFeature.nonStandardGroupCodes.length > 1) {
                        sortByCode = responseData.sort(function (a, b) {
                            return a.optionCode.localeCompare(b === null || b === void 0 ? void 0 : b.optionCode);
                        });
                        sortByGroupCode = sortByCode.sort(function (a, b) { return a.nsoGroupCode.localeCompare(b.nsoGroupCode); });
                        responseData = sortByGroupCode;
                    }
                    setNsoOptions(responseData);
                    setNsMetadata({
                        effectiveDate: req.effectiveDate,
                        featureId: rowFeature.featureId,
                        groupCode: (_b = rowFeature.nonStandardGroupCodes.length < 1) !== null && _b !== void 0 ? _b : rowFeature.nonStandardGroupCode,
                        groupCodes: rowFeature.nonStandardGroupCodes,
                        productVersion: (_c = searchParams.get('productVersion')) !== null && _c !== void 0 ? _c : '',
                        startAt: rsp.body.startAt,
                        businessUnitCode: (_d = req.businessUnitCode) !== null && _d !== void 0 ? _d : '',
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [searchParams, rowFeature, businessUnitCode]);
    var isNso = useMemo(function () {
        if (rowFeature.nonStandardIndicator) {
            getNsos();
            return true;
        }
        return false;
    }, [rowFeature, getNsos]);
    var OptionRestrictionList = function (restriction) {
        var optRestrictionList = [];
        Object.entries(restriction).forEach(function (item) {
            var newObj = {
                rstrctnId: item[1].rstrctnId,
                reasonCode: item[1].reasonCode,
                bulletinNbr: item[1].bulletinNbr,
                comment: item[1].comment,
                startDate: item[1].startDate,
                endDate: item[1].endDate,
            };
            optRestrictionList.push(newObj);
        });
        return optRestrictionList;
    };
    var OptionRoyalityList = function (royalties) {
        var optRestrictionList = [];
        Object.entries(royalties).forEach(function (item) {
            var newObj = {
                royaltyId: item[1].royaltyId,
                royaltyDesc: item[1].royaltyDesc,
                startDate: item[1].startDate,
                endDate: item[1].endDate,
            };
            optRestrictionList.push(newObj);
        });
        return optRestrictionList;
    };
    var DerivedOptionsList = function (item) {
        var newArr;
        derivedOptions.forEach(function (i) {
            if (i.parentFeatureId === rowFeature.featureId && i.commonOptionCode === item) {
                newArr = i.derviedOptions;
            }
        });
        return newArr;
    };
    var mapOptionsList = function () {
        var optList = [];
        if (rowFeature.options) {
            Object.entries(rowFeature.options).forEach(function (item) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
                if (item[0].startsWith('option_'))
                    return;
                if (item[1].type !== '3') {
                    var optRestrictionList = OptionRestrictionList((_a = item[1].restriction) !== null && _a !== void 0 ? _a : []);
                    var optRoyalitesList = OptionRoyalityList((_b = item[1].royalties) !== null && _b !== void 0 ? _b : []);
                    var derivedOptionsList = DerivedOptionsList(item[0]);
                    var newObj = {
                        optionCode: item[0],
                        optionDescription: optRestrictionList.length
                            ? "(RESTRICTED) ".concat((_d = (_c = item[1].description) === null || _c === void 0 ? void 0 : _c[DEFAULT_LANGUAGE_CODE]) === null || _d === void 0 ? void 0 : _d.desc)
                            : (_f = (_e = item[1].description) === null || _e === void 0 ? void 0 : _e[DEFAULT_LANGUAGE_CODE]) === null || _f === void 0 ? void 0 : _f.desc,
                        restricted: optRestrictionList,
                        royalty: optRoyalitesList,
                        derivedOptions: derivedOptionsList !== null && derivedOptionsList !== void 0 ? derivedOptionsList : [],
                        minLeadTime: item[1].minLeadtime,
                        commercialSif: ((_j = (_h = (_g = item[1]) === null || _g === void 0 ? void 0 : _g.channels) === null || _h === void 0 ? void 0 : _h[NA_CONTRACT_CHANNEL]) === null || _j === void 0 ? void 0 : _j.sifPromotable) ||
                            ((_m = (_l = (_k = item[1]) === null || _k === void 0 ? void 0 : _k.channels) === null || _l === void 0 ? void 0 : _l[NA_CONTRACT_CHANNEL_CAP]) === null || _m === void 0 ? void 0 : _m.sifPromotable)
                            ? 'Y'
                            : '',
                        commercialPb: ((_q = (_p = (_o = item[1]) === null || _o === void 0 ? void 0 : _o.channels) === null || _p === void 0 ? void 0 : _p[NA_CONTRACT_CHANNEL]) === null || _q === void 0 ? void 0 : _q.priceBookPromotable) ||
                            ((_t = (_s = (_r = item[1]) === null || _r === void 0 ? void 0 : _r.channels) === null || _s === void 0 ? void 0 : _s[NA_CONTRACT_CHANNEL_CAP]) === null || _t === void 0 ? void 0 : _t.priceBookPromotable)
                            ? 'Y'
                            : '',
                        commercialPrd: ((_w = (_v = (_u = item[1]) === null || _u === void 0 ? void 0 : _u.channels) === null || _v === void 0 ? void 0 : _v[NA_CONTRACT_CHANNEL]) === null || _w === void 0 ? void 0 : _w.cetPromotable) ||
                            ((_z = (_y = (_x = item[1]) === null || _x === void 0 ? void 0 : _x.channels) === null || _y === void 0 ? void 0 : _y[NA_CONTRACT_CHANNEL_CAP]) === null || _z === void 0 ? void 0 : _z.cetPromotable)
                            ? 'Y'
                            : '',
                        GSASif: ((_2 = (_1 = (_0 = item[1]) === null || _0 === void 0 ? void 0 : _0.channels) === null || _1 === void 0 ? void 0 : _1[GSA_CHANNEL]) === null || _2 === void 0 ? void 0 : _2.sifPromotable) ? 'Y' : '',
                        GSAPb: ((_5 = (_4 = (_3 = item[1]) === null || _3 === void 0 ? void 0 : _3.channels) === null || _4 === void 0 ? void 0 : _4[GSA_CHANNEL]) === null || _5 === void 0 ? void 0 : _5.priceBookPromotable) ? 'Y' : '',
                        GSAPrd: ((_8 = (_7 = (_6 = item[1]) === null || _6 === void 0 ? void 0 : _6.channels) === null || _7 === void 0 ? void 0 : _7[GSA_CHANNEL]) === null || _8 === void 0 ? void 0 : _8.cetPromotable) ? 'Y' : '',
                        B2BSif: ((_11 = (_10 = (_9 = item[1]) === null || _9 === void 0 ? void 0 : _9.channels) === null || _10 === void 0 ? void 0 : _10[B2B_CHANNEL]) === null || _11 === void 0 ? void 0 : _11.sifPromotable) ? 'Y' : '',
                        B2BPb: ((_14 = (_13 = (_12 = item[1]) === null || _12 === void 0 ? void 0 : _12.channels) === null || _13 === void 0 ? void 0 : _13[B2B_CHANNEL]) === null || _14 === void 0 ? void 0 : _14.priceBookPromotable) ? 'Y' : '',
                        B2BPrd: ((_17 = (_16 = (_15 = item[1]) === null || _15 === void 0 ? void 0 : _15.channels) === null || _16 === void 0 ? void 0 : _16[B2B_CHANNEL]) === null || _17 === void 0 ? void 0 : _17.cetPromotable) ? 'Y' : '',
                        mfgOptionModelCode: item[1].mfgOptionModelCode,
                        prcOptionModelCode: item[1].prcOptionModelCode,
                        pbSubHeading: item[1].pbSubHeading,
                    };
                    optList.push(newObj);
                }
            });
        }
        return optList;
    };
    var mapFabricOption = function () {
        var optList = [];
        if (rowFeature.options) {
            Object.entries(rowFeature.options).forEach(function (item) {
                var _a, _b;
                var newObj = {
                    optionCode: item[0],
                    optionDescription: (_b = (_a = item[1].description) === null || _a === void 0 ? void 0 : _a[DEFAULT_LANGUAGE_CODE]) === null || _b === void 0 ? void 0 : _b.desc,
                    gsaInd: item[1].gsaInd,
                    pbSubHeading: item[1].pbSubHeading,
                    minLeadTime: Number(item[1].minLeadtime),
                    prcOptionModelCode: item[1].prcOptionModelCode,
                    mfgOptionModelCode: item[1].mfgOptionModelCode,
                };
                optList.push(newObj);
            });
        }
        return optList;
    };
    var optionsList = useState(mapOptionsList())[0];
    var _h = useState([]), fabricOptionList = _h[0], setFabricOptionList = _h[1];
    var _j = useState(false), isModalOpen = _j[0], setIsModalOpen = _j[1];
    var _k = useState(false), isModalDerviedOpen = _k[0], setIsModalDerviedOpen = _k[1];
    var _l = useState(false), isShowRestriction = _l[0], setIsShowRestriction = _l[1];
    var _m = useState([]), optionRowRestricted = _m[0], setOprtionRowRestricted = _m[1];
    var _o = useState([]), optionRowRoyality = _o[0], setOprtionRowRoyality = _o[1];
    var _p = useState([]), derivedOptionsData = _p[0], setDerivedOptionsData = _p[1];
    function handleOpenModal(value, optionRestrictions, optionRoyalties) {
        setIsModalOpen(true);
        setIsShowRestriction(value);
        setOprtionRowRestricted(optionRestrictions);
        setOprtionRowRoyality(optionRoyalties);
    }
    var handleOpenDerivedModal = function (optionList) {
        setIsModalDerviedOpen(true);
        setDerivedOptionsData(optionList);
    };
    var rowSpanCount = !isFabric && !isNso ? optionsList.length + 1 : 2;
    var getMetadataForRow = function () { return nsMetadata; };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ sx: { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '', border: 'none' } }, { children: [_jsx(TableCell, __assign({ className: styles.rowStyle, rowSpan: rowSpanCount }, { children: rowFeature.step })), _jsx(TableCell, __assign({ className: styles.rowStyle, rowSpan: rowSpanCount }, { children: rowFeature.type })), _jsx(TableCell, __assign({ className: styles.rowStyle, rowSpan: rowSpanCount }, { children: rowFeature.featureName })), showOptionalColumns !== 'Show None' && (_jsx(TableCell, __assign({ className: styles.rowStyle, sx: { wordBreak: 'break-all' }, rowSpan: rowSpanCount }, { children: showOptionalColumns === 'Engineering Model Codes'
                            ? rowFeature.mfgFeatureModelCode
                            : rowFeature.prcFeatureModelCode }))), Object.keys(rowFeature.options).length === 0 && !isNso && (_jsx(TableCell, { sx: { borderRight: 'solid 1px black' }, colSpan: showOptionalColumns !== 'Show None' ? 13 : 12 }))] })), isFabric && (_jsxs(TableRow, __assign({ sx: { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '' } }, { children: [_jsx(TableCell, { sx: { border: 'none' } }), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: _jsx(FabricOptionsInput, { isEditMode: false, changeCodeHandler: function () { return null; }, featureDescription: rowFeature.featureName, featureStep: (_b = rowFeature.step) === null || _b === void 0 ? void 0 : _b.toString(), options: fabricOptionList, showOptionalColumns: showOptionalColumns, fromInfo: true, modelOpenHandler: function () { return __awaiter(_this, void 0, void 0, function () {
                                var optList;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, getFabricOptions((_a = rowFeature.featureId) !== null && _a !== void 0 ? _a : '')];
                                        case 1:
                                            optList = _b.sent();
                                            setFabricOptionList(optList);
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }) })), showOptionalColumns !== 'Show None' && _jsx(TableCell, { className: styles.rowOption }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), userHasRoyaltyPrivileges && _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", sx: {
                            borderLeft: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", sx: {
                            borderRight: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", sx: {
                            borderRight: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } })] }))), isNso && (_jsxs(TableRow, __assign({ sx: { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '' } }, { children: [_jsx(TableCell, { sx: { border: 'none' } }), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: _jsx(NonStdOptionsInput, { options: nsOptions, isEditMode: false, changeCodeHandler: function () { return null; }, featureDescription: (_c = rowFeature.featureName) !== null && _c !== void 0 ? _c : '', featureStep: (_e = (_d = rowFeature.step) === null || _d === void 0 ? void 0 : _d.toString()) !== null && _e !== void 0 ? _e : '', showOptionalColumns: showOptionalColumns, nsoMetadata: getMetadataForRow() }) })), showOptionalColumns !== 'Show None' && _jsx(TableCell, { className: styles.rowOption }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), userHasRoyaltyPrivileges && _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption } }), _jsx(TableCell, { align: "left", sx: {
                            borderLeft: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", sx: {
                            borderRight: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }), _jsx(TableCell, { align: "left", sx: {
                            borderRight: '1px solid #AFA99F',
                            borderBottom: 'none',
                            backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                        } })] }))), !isFabric &&
                (optionsList === null || optionsList === void 0 ? void 0 : optionsList.map(function (singleOption, i) {
                    var key = "".concat(singleOption.optionCode, "_").concat(i);
                    return (_jsxs(TableRow, __assign({ sx: { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '' } }, { children: [_jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption }, sx: { border: 'none' } }, { children: singleOption.pbSubHeading })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: "".concat(singleOption.optionCode, " - ").concat(singleOption.optionDescription) })), showOptionalColumns !== 'Show None' && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.rowStyle, sx: { wordBreak: 'break-all', border: 'none' }, rowSpan: rowSpanCount }, { children: showOptionalColumns === 'Engineering Model Codes'
                                        ? singleOption.mfgOptionModelCode
                                        : singleOption.prcOptionModelCode })) })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: Object.keys(singleOption.restricted).length ? (_jsxs(Button, __assign({ className: styles.btnRestric, onClick: function () {
                                        return handleOpenModal(true, singleOption.restricted, singleOption.royalty);
                                    } }, { children: [_jsx(StarsIcon, { sx: { width: 20, height: 20 } }), _jsx(Box, __assign({ sx: { marginLeft: '5px' } }, { children: "View" }))] }))) : null })), userHasRoyaltyPrivileges && (_jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: Object.keys(singleOption.royalty).length ? (_jsxs(Button, __assign({ className: styles.btnRoyalty, onClick: function () {
                                        return handleOpenModal(false, singleOption.restricted, singleOption.royalty);
                                    } }, { children: [_jsx(StarsIcon, { sx: { width: 20, height: 20 } }), _jsx(Box, __assign({ sx: { marginLeft: '5px' } }, { children: "View" }))] }))) : null }))), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: singleOption.derivedOptions.length ? (_jsx(Button, __assign({ onClick: function () { return handleOpenDerivedModal(singleOption.derivedOptions); } }, { children: _jsx(Box, __assign({ sx: { marginLeft: '5px', textDecoration: 'underline' } }, { children: "View" })) }))) : null })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption } }, { children: singleOption.minLeadTime })), _jsx(TableCell, __assign({ align: "left", sx: {
                                    borderLeft: '1px solid #AFA99F',
                                    borderBottom: 'none',
                                    backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                                } }, { children: singleOption.commercialSif })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }, { children: singleOption.commercialPb })), _jsx(TableCell, __assign({ align: "left", sx: {
                                    borderRight: '1px solid #AFA99F',
                                    borderBottom: 'none',
                                    backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                                } }, { children: singleOption.commercialPrd })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }, { children: selectedPromoColumn === GSA_CHANNEL ? singleOption.GSASif : singleOption.B2BSif })), _jsx(TableCell, __assign({ align: "left", classes: { root: styles.rowOption }, sx: { backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8' } }, { children: selectedPromoColumn === GSA_CHANNEL ? singleOption.GSAPb : singleOption.B2BPb })), _jsx(TableCell, __assign({ align: "left", sx: {
                                    borderRight: '1px solid #AFA99F',
                                    borderBottom: 'none',
                                    backgroundColor: index % 2 === 0 ? '#F7F4EF' : '#FFFDF8',
                                } }, { children: selectedPromoColumn === GSA_CHANNEL ? singleOption.GSAPrd : singleOption.B2BPrd }))] }), key));
                })), isModalOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(OptionRoyaltyRestrictionModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, isShowRestriction: isShowRestriction, rowFeature: {
                        step: rowFeature.step,
                        type: rowFeature.type,
                        featureName: rowFeature.featureName,
                        optionRestrictions: optionRowRestricted,
                        optionRoyalties: optionRowRoyality,
                    } }) }))), isModalDerviedOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(DerivedFeaturesModal, { isOpen: isModalDerviedOpen, setIsOpen: function () { return setIsModalDerviedOpen(false); }, rowFeature: rowFeature, derivedOptions: derivedOptionsData, showOptionalColumns: showOptionalColumns }) })))] }));
}
export function getFabricOptions(featureId) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, Promise, function () {
        var productVersion, response, optList_1;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    productVersion = (_a = sessionStorage.getItem('productVersion')) !== null && _a !== void 0 ? _a : '';
                    if (!(productVersion && featureId)) return [3 /*break*/, 2];
                    return [4 /*yield*/, infoSelect({
                            featureId: featureId,
                            productVersion: productVersion,
                        }, 'options')];
                case 1:
                    response = _f.sent();
                    optList_1 = [];
                    Object.entries((_e = (_d = (_c = (_b = response === null || response === void 0 ? void 0 : response.body) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.feature) === null || _d === void 0 ? void 0 : _d.options) !== null && _e !== void 0 ? _e : {}).forEach(function (item) {
                        var _a, _b;
                        var option = item[1];
                        var newObj = {
                            optionCode: item[0],
                            optionDescription: (_b = (_a = option.description) === null || _a === void 0 ? void 0 : _a[DEFAULT_LANGUAGE_CODE]) === null || _b === void 0 ? void 0 : _b.desc,
                            gsaInd: option.gsaInd,
                            pbSubHeading: option.pbSubHeading,
                            minLeadTime: Number(option.minLeadtime),
                            prcOptionModelCode: option.prcOptionModelCode,
                            mfgOptionModelCode: option.mfgOptionModelCode,
                        };
                        optList_1.push(newObj);
                    });
                    return [2 /*return*/, optList_1];
                case 2: return [2 /*return*/, []];
            }
        });
    });
}
